* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: var(--base-font-size);
  font-family: var(--base-font);
}

body {
  background: var(--body-background);
  color: var(--text-color);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Selection colors */
/*
::selection,
::-webkit-selection {
  background: var(--selection-bg);
  color: var(--selection-color);
  text-shadow: none;
}
*/

img::selection {
  background: transparent;
}

/* Media */
img,
picture,
video,
audio,
embed,
object,
input,
iframe {
  max-width: 100%;
  margin: 0;
}

img {
  display: inline-block;

  a:hover & {
    border: none;
    background-color: none;
  }
}

img,
svg {
  height: auto;
}

x::-ms-reveal,
img[src*='.svg'] {
  width: 100%;
}

a img {
  border: none;
}

/* Typography */
a {
  color: inherit;
}

b, strong {
  font-weight: bold;
}