.nav {
  width: $sidebar_width;
  height: 100%;
  background-image: linear-gradient(to right top, #3A567D, #2E4360);
  @apply text-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.nav__logo {
  svg {
    width: space(xl);
  }
}

.nav__item {
  cursor: pointer;

  ul {
    padding-left: 44px;
    padding-top: 12px;
  }
  li {
    padding-bottom: 12px;
  }
  a {
    display: block;
  }

  &--open {
    margin-bottom: 0;
  }
  &--sub {
    cursor: default;
    
    > .row {
      cursor: pointer;
    }
  }
}

.nav__item-icon {
  width: 44px;
  display: flex;
  align-items: flex-start;
}

.nav__item-caret {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform .4s;

  .nav__item--open & {
    transform: rotate(180deg);
  }
}

.nav__user-container {
  position: relative;
}
.nav__user {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: $azure;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  text-transform: uppercase;
}
.nav__user-menu {
  width: 100%;
  padding: 2px;
  @apply bg-white;
  border-radius: 5px;
  position: absolute;
  bottom: 110%;
  left: 0;
  color: #616365;

  li {
    cursor: pointer;

    &:hover {
      background-color: rgba(#73ACF9, .2);
    }
  }
  a {
    display: block;
    height: 100%;
    padding: 8px;
  }
}

.nav__clock-hour {
  font-size: 32px;
  line-height: 1;
  opacity: 0.5;
}

.nav__clock-date {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;

  &::first-letter {
    text-transform: uppercase;
  }
}

.nav__logout {
  cursor: pointer;
}

@include mq-max(bk(desk-m-down)) {
  .nav {
    width: $sidebar_width_md;
  }
  .nav__logo {
    svg {
      width: space_md(xl);
    }
  }
}

@include mq-max(bk(desk-down)) {
  .nav {
    display: none;
  }
}