.spinner {
  @apply flex items-center;

  svg {
    animation: spinner-rotate 2s linear infinite;
  }

  circle {
    stroke-linecap: round;
    animation: spinner-dash 1.5s ease-in-out infinite;
  }

  &--black {
    circle {
      stroke: #616365;
    }
  }
  &--white {
    circle {
      stroke: #FFFFFF;
    }
  }
  &--blue {
    circle {
      stroke: #73ACF9;
    }
  }
  &--red {
    circle {
      @apply stroke-red;
    }
  }
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
