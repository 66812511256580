/* .copy-clipboard {
  svg {
    width: 15px;
    min-width: 15px;
    cursor: pointer;
  }
} */

.copy-clipboard__svg {
  @apply w-5 min-w-5 h-5 flex justify-center items-center cursor-pointer;
  /* min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; */

  svg {
    @apply w-5;
  }

  &--copied {
    @apply text-green;
    /* path {
      @apply stroke-green;
      fill: #4BAF62;
    } */
  }
  &--error {
    @apply text-red;
    /* path {
      @apply stroke-red;
      fill: #F27D73;
    } */
  }
}