.monitoring-modal {
  @apply max-w-[600px];
}

.patient-modal {
  @apply max-w-[600px];
}

.phone-modal {
  @apply max-w-[600px];
}

.email-modal {
  @apply max-w-[600px];
}

.reports-modal {
  @apply max-w-[1000px];
}

.import-modal {
  @apply max-w-[1280px];
}