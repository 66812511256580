.percentage-widget {
  @apply
    w-48 h-48 rounded-full bg-[#f2f4fe] bg-opacity-50
    flex justify-center items-center
    relative
  ;
  box-shadow: inset 0 0 8px 4px rgba(115,172,249, .1);

  --progress: 0;
  circle {
    stroke: #73ACF9;
    stroke-dasharray: 276;
    stroke-dashoffset: calc((100 - var(--progress)) * 0.01 * 276);
    transform: rotate(-90deg);
    transform-origin: center center;
    transition: stroke-dashoffset .5s .25s;
  }

  &--success {
    circle {
      stroke: #4BAF62;
    }
  }
  &--warning {
    circle {
      stroke: #FDC55A;
    }
  }
  &--error {
    circle {
      stroke: #F27D73;
    }
  }

  &--s {
    @apply w-36 h-36;
    box-shadow: inset 0 0 6px 3px rgba(115,172,249, .1);
  }
  &--xs {
    @apply w-24 h-24;
    box-shadow: inset 0 0 4px 2px rgba(115,172,249, .1);
  }
}

.percentage-widget__svg {
  @apply w-[90%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.percentage-widget__inner {
  @apply
    w-[60%] h-[60%] bg-white rounded-full
    flex justify-center items-center
    text-2xl font-semibold select-none;
  box-shadow: 0px 0px 8px 2px rgba(115,172,249, .15);

  .percentage-widget--s & {
    @apply w-[78px] h-[78px] text-lg;
    box-shadow: 0 0 8px 1px rgba(115,172,249, .15);
  }
  .percentage-widget--xs & {
    @apply w-[52px] h-[52px] text-base;
    box-shadow: 0 0 8px 1px rgba(115,172,249, .15);
  }
}
