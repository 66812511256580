.sidebar__backdrop {
  @apply fixed inset-0 bg-gray-900 bg-opacity-40 z-40 lg:hidden lg:z-auto transition-opacity duration-200 opacity-0 pointer-events-none;

  &--open {
    @apply opacity-100 pointer-events-auto;
  }
}

.sidebar {
  @apply  flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 flex-shrink-0 bg-gray-800 p-4 transition-all duration-200 ease-in-out -translate-x-64;

  &--open {
    @apply translate-x-0;
  }
}

.sidebar__header {
  @apply flex justify-between mb-10 pr-3 sm:px-2;
}

.sidebar__close {
  @apply lg:hidden text-gray-500 hover:text-gray-400;
}

.sidebar__logo {
  @apply block lg:sidebar-expanded:w-full;
}

.sidebar__sections {
  @apply space-y-8;
}

.sidebar__label {
  @apply text-xs uppercase text-gray-500 font-semibold pl-3;
}

.sidebar__label-dots {
  @apply hidden lg:block lg:sidebar-expanded:hidden text-center w-6;
}

.sidebar__label-text {
  @apply lg:hidden lg:sidebar-expanded:block;
}

.sidebar__section-list {
  @apply mt-3;
}

.sidebar__item {
  @apply px-3 py-2 rounded-sm mb-0.5 last:mb-0;

  &--active {
    @apply bg-gray-900;
  }
}

.sidebar__item-wrapper {
  @apply block text-gray-200 hover:text-white truncate transition duration-150 cursor-pointer;

  &--active {
    @apply hover:text-gray-200;
  }
}

.sidebar__item-label {
  @apply text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 duration-200;
}

.sidebar__item-arrow {
  @apply flex flex-shrink-0 ml-2;
}

.sidebar__item-arrow-svg {
  @apply w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400;
}

.sidebar__group {
  @apply lg:hidden lg:sidebar-expanded:block;
}

.sidebar__group-list {
  @apply pl-9 mt-1;
}

.sidebar__group-item {
  @apply mb-1 text-sm last:mb-0;
}

.sidebar__group-link {
  @apply block text-gray-400 hover:text-gray-200 transition duration-150 truncate;

  &--active {
    @apply !text-indigo-500;
  }
}

.sidebar__group-link-label {
  @apply text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 duration-200;
}

.sidebar__expand {
  @apply pt-3 hidden lg:inline-flex justify-end mt-auto;
}

.sidebar__expand-wrapper {
  @apply px-3 py-2;
}

.sidebar__expand-icon {
  @apply w-6 h-6 fill-current sidebar-expanded:rotate-180;
}
