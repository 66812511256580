.float-widget {
  @apply w-12 h-12 bg-blue rounded-full text-white
  flex justify-center items-center
  fixed z-40
  right-4 sm:right-6 lg:right-8
  bottom-4 sm:bottom-6 lg:bottom-8
  cursor-pointer select-none;
  box-shadow: 0 4px 12px rgba(0,0,0,0.4);
}

.float-widget__bg {
  @apply w-full h-full bg-gray-900 bg-opacity-40
  fixed top-0 left-0 z-40;
}

.float-widget__menu {
  @apply min-w-[200px]
  flex flex-col items-end gap-y-2
  absolute bottom-[120%] right-0;
}

.float-widget__item {
  @apply pr-1 flex items-center gap-x-4
  whitespace-nowrap;
}

.float-widget__item-label {
  @apply px-4 py-2 bg-white rounded
  text-sm text-grey-text
  shadow-lg;
}

.float-widget__item-icon {
  @apply w-10 h-10 rounded-full bg-blue text-white
  flex justify-center items-center
  cursor-pointer;
  box-shadow: 0 4px 12px rgba(0,0,0,0.4);
}