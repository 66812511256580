.info {
  @apply flex items-start relative;
}

.info__text {
  @apply bg-white rounded border border-gray-300
  absolute left-1/2 bottom-[120%] z-1 -translate-x-1/2
  font-normal shadow-md;

  &.xs {
    @apply w-40;
  }
  &.sm {
    @apply w-52;
  }
  &.md {
    @apply w-72;
  }
  &.lg {
    @apply w-80;
  }
}
