/* TABS */
.account-tabs__nav {
  @apply lg:border-r lg:border-gray-300;
}
.account-tabs__list {
  @apply flex overflow-x-auto lg:block lg:pr-5;
}
.account-tabs__item {
  @apply px-6 lg:px-2 py-2 rounded-t lg:rounded
  flex items-center gap-x-2
  cursor-pointer text-gray-400 whitespace-nowrap;

  svg {
    @apply hidden lg:block;
  }

  &--active {
    background-color: #73ACF9;
    @apply text-white;
  }
}
.account-tabs__item-label {
  @apply text-gray-700;
}
.account-tabs__item--active .account-tabs__item-label {
  @apply text-white;
}
.account-tabs__section {
  @apply py-5 lg:pl-5;
}

/* PLANS */
.account-plan {
  @apply h-full p-2 border border-gray-300 border-t-8 border-t-blue rounded-lg;
}
.account-plan__header {
  @apply pb-2 border-b border-gray-300;
}
.account-plan__title {
  @apply mb-4 font-semibold text-lg capitalize;
}
.account-plan__body {
  @apply pt-2;
}

/* ROW */
.account-row {
  @apply py-2 border-b border-gray-300;
}

/* BANNER */
.account-banner__container {
  position: relative;
  margin-bottom: 100px;
}
.account-banner {
  width: 100%;
  padding: 10px 20px;
  background-color: #FDC55A;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

@include mq-max(bk(desk-down)) {
  .account__box {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
  .account-tabs__nav,
  .account-tabs__list,
  .account-tabs__section > div {
    padding: 0;
  }
  .account-tabs__nav {
    border-right: none;
    border-bottom: 1px solid #BEC2C6;
    padding-bottom: 20px;
  }
  .account-tabs__section {
    padding-top: 20px;
  }
  .account-plans {
    .row {
      > [class*="col"] {
        margin-bottom: 16px;
      }
    }
  }
}