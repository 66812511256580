.csv-table {
  @apply w-full text-left text-sm;

  th {
    @apply p-2 first:pl-0 font-semibold border-b border-grey-border;
  }
  td {
    @apply p-2 first:pl-0 border-b border-grey-border;
  }
}

.csv__tabs {
  @apply flex border-b border-grey-border;
}
.csv__tabs-item {
  @apply px-4 py-2
  flex items-center
  cursor-pointer;

  &.active {
    @apply bg-blue text-white rounded-t-md;
  }
}

.csv__table {
  @apply w-full text-left text-sm;

  th {
    @apply p-2 font-semibold border-b border-grey-border;
  }
  td {
    @apply max-w-[15vw] p-2 border-b border-grey-border;
  }
}

.csv__tr {
  &--error {
    @apply bg-red bg-opacity-10 text-red;
  }
  &--noborder {
    td {
      @apply border-b-0;
    }
  }
}

.csv__icon {
  @apply w-8 h-8
  flex justify-center items-center
  cursor-pointer;
}