.radio {
  @apply text-sm cursor-pointer;

  input {
    @apply sr-only;
  }

  &--error {
    @apply text-red;
  }
  &--disabled {
    @apply opacity-50 pointer-events-none;
  }
}

.radio__mark {
  @apply w-5 h-5 p-1 rounded-full border-2 border-blue;

  &::before {
    content: '';
    @apply block w-full h-full rounded-full bg-blue opacity-0;
  }

  input:checked + &::before {
    @apply opacity-100;
  }

  .radio--error & {
    @apply border-red;

    &::before {
      @apply bg-red;
    }
  }
}
