.accordion {
  @apply px-5 py-4 rounded-sm border border-gray-200;
}

.accordion__button {
  @apply flex items-center justify-between w-full mb-1;
}

.accordion__title {
  @apply text-sm font-medium;
}

.accordion__icon {
  @apply flex-shrink-0 stroke-gray-400 group-hover:stroke-gray-500 ml-3;

  &--open {
    @apply rotate-180;
  }
}

.accordion__content{
  @apply text-sm;
}