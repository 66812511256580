.react-time-picker__wrapper {
  height: 40px;
  padding-right: 8px;
  padding-left: 16px;
  background-color: #E7EAEE;
  border: 1px solid #BEC2C6 !important;
  border-radius: 10px;

  .time-picker--error & {
    border-color: #F27D73 !important;
  }
}

.react-time-picker__button {
  &:enabled:hover,
  &:enabled:focus {
    .react-time-picker__button__icon {
      stroke: #73ACF9 !important;
    }
  }
}

.react-time-picker__inputGroup {
  display: flex;
  align-items: center;
}

.react-time-picker__inputGroup__input {
  width: 20px;
  color: #616365 !important;

  &:focus {
    outline: 1px solid #73ACF9;
  }
}