.dropzone {
  @apply w-full h-full
  bg-blue bg-opacity-90 text-white
  fixed top-0 left-0 z-50
  flex flex-col justify-center items-center;
}

.dropzone__title {
  @apply mt-4 text-2xl 2xl:text-3xl;
}

.dropzone__paths {
  animation: drop-move 1s infinite alternate ease-in-out;
}

@keyframes drop-move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-2px, -2px);
  }
}
