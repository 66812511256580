.title {
  @apply font-bold text-grey-dark;

  &--xs {
    @apply text-lg leading-tight;
  }
  &--s {
    @apply text-2xl leading-tight;
  }
  &--m {
    @apply text-3xl leading-tight;
  }
  &--l {
    @apply text-5xl leading-tight;
  }
}

.text {
  line-height: 1.4;

  &-default {
    @apply text-grey-dark;
  }
  &-info {
    @apply text-blue;
  }
  &-success {
    @apply text-green;
  }
  &-warning {
    @apply text-yellow;
  }
  &-error {
    @apply text-red;
  }
  &-grey {
    @apply text-grey-text;
  }
}
