.percentage-progress {
  @apply
    w-12 h-9 rounded-full flex justify-center items-center relative
    lg:w-full lg:min-w-36 lg:max-w-52 lg:h-6 lg:px-3 lg:py-1 lg:justify-start
  ;
  box-shadow: inset 0 5px 10px rgba(hexToRgb(#BCD2F0), .5), inset 0 -5px 10px rgba(hexToRgb(#FFFFFF), .5);

  &--warning {
    .percentage-progress__bar {
      background-color: #FDC55A;
    }
  }
  &--error {
    .percentage-progress__bar {
      background-color: #F27D73;
    }
  }
  &--archived {
    .percentage-progress__bar {
      background-color: #C2C5C7;
    }
  }
}

.percentage-progress__bar {
  @apply
    w-full h-full
    lg:w-[var(--width)] min-w-4 lg:h-4 bg-green rounded-full;
  ;
  /* width: 100%;
  min-width: 16px;
  height: 16px;
  background-color: #4BAF62;
  border-radius: 8px; */
}

.percentage-progress__label {
  /* position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: 14px;
  mix-blend-mode: difference; */
  @apply hidden lg:block absolute top-1/2 left-1/2 z-1 -translate-x-1/2 -translate-y-1/2 text-sm mix-blend-difference;

  span {
    /* margin-right: 2px;
    font-weight: 600; */
    @apply mr-0.5 font-semibold;
  }
}

.percentage-progress__label-mobile {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:hidden;
  /* display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* display: none; */
}

@include mq-max(bk(desk-down)) {
  /* .percentage-progress {
    width: 48px;
    height: 32px;
    border-radius: 16px;
    min-width: auto;
    max-width: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .percentage-progress__bar {
    width: 100% !important;
    height: 100% !important;
    border-radius: 16px;
  }
  .percentage-progress__label {
    display: none;
  }
  .percentage-progress__label-mobile {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
