.mobile-banner {
  @apply w-full h-full;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  @apply bg-white;
  display: none;
}

@include mq-max(bk(desk)) {
  .mobile-banner {
    display: flex;
    display: none;
  }
}