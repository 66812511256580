.checkbox {
  @apply text-sm cursor-pointer;

  input {
    @apply sr-only;
  }

  &--error {
    @apply text-red;
  }
}

.checkbox__mark {
  @apply w-5 h-5 p-1 rounded border-2 border-blue;

  &::before {
    content: '';
    @apply block w-full h-full rounded-sm bg-blue opacity-0;
  }

  input:checked + &::before {
    @apply opacity-100;
  }

  .checkbox--error & {
    @apply border-red;
  }
}
