.account-complete {
  width: 600px;
}

.account-complete__progress {
  position: relative;
}

.account-complete__dot {
  @apply w-5 h-5;
  border-radius: 50%;
  border: 1px solid $black;
}

@include mq-max(bk(desk-down)) {
  .account-complete {
    background-color: transparent;
    box-shadow: none;
  }
}