html,
body,
#root,
.page {
  height: 100%;
}

body {
  @apply font-montserrat font-medium text-grey-dark bg-[#f2f4fe];
  /* bg-slate-100 bg-[#ebf4fe] */
}

.page__content {
  @apply relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden;
}

.container {
  @apply mx-auto max-w-[90%];
}
