.patient-message {
  @apply py-1 pl-2 mb-5
  rounded-md rounded-bl-none
  bg-grey-light relative;

  &::before {
    content: "";
    @apply absolute top-full left-0 border-[6px]
    border-l-grey-light border-t-grey-light
    border-r-transparent border-b-transparent;
  }
}

.patient-message__heading {
  @apply flex justify-between items-center gap-x-1 truncate relative z-1;
}
.patient-message__name {
  @apply flex items-center gap-x-1;
}
.patient-message__name-text {
  @apply flex-1 text-sm font-semibold truncate hover:underline;
}
.patient-message__date-container {
  @apply flex items-center;
}
.patient-message__date {
  @apply text-xs whitespace-nowrap;
}

.patient-message__content {
  @apply flex items-start gap-x-4;
}
.patient-message__text {
  @apply flex-1 text-sm my-1;
}
