.filters {
  @apply flex-wrap gap-x-4 gap-y-2;
}

.filters__select {
  @apply w-full min-w-52 max-w-[400px] md:max-w-52 md:w-52;

  > div {
    @apply w-full;
  }
}

.filters__chips {
  @apply flex flex-wrap items-center gap-2;
}

@include mq-max(bk(desk-down)) {
  .filters {
    flex-wrap: wrap;
    padding-left: 0;

    > div {
      margin-right: 0;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  .filters__select {
    min-width: auto;
    max-width: none;
    width: 100%;
  }
  .filters__chips {
    width: 100%;
    flex-wrap: wrap;
    padding-left: 0;
  }
  .filters__chips-group {
    width: 100%;

    .chip {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}