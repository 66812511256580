.alert {
  @apply p-4 border rounded-md
  flex items-center;

  &--danger {
    @apply bg-red-50 border-red-500 text-red-500;
  }
  &--generic {
    @apply bg-grey-light border-grey-dark;
  }
  &--info {
    @apply bg-blue-white border-blue-dark text-blue-dark;
  }
  &--success {
    @apply bg-green-50 border-green text-green;
  }
  &--warning {
    @apply bg-yellow-50 border-yellow-600 text-yellow-600;
  }
}

.alert__icon {
  @apply min-w-5 flex items-start mr-3;
}

.alert__content {
  @apply w-full text-sm;
}
