.login {
  @apply flex flex-col items-center;
}

.login__box {
  width: 600px;
}

@include mq-max(bk(tab-down)) {
  .login__box {
    width: 100%;
    box-shadow: none;
  }
  .login__remember {
    flex-wrap: wrap;

    > .text {
      margin-top: space_md(m);
    }
  }
}