.textarea__container {
  width: 100%;

  &--error {
    color: #F27D73;

    .textarea {
      @apply border-red text-red;
    }
  }

  &--focused {
    .textarea {
      @apply border-blue;
      color: #616365;
    }
  }
}

.textarea {
  @apply
    block w-full h-28 px-3 py-2 resize-none
    bg-white border rounded border-gray-300 hover:border-gray-400 focus:border-blue outline-none
    text-sm text-grey-dark placeholder-gray-400
  ;
}
