.contact-method__item {
  @apply mb-2 last:mb-0 gap-x-2;

  &--disabled {
    @apply opacity-50 pointer-events-none;
  }
}

.contact-method__item-label {
  @apply text-sm uppercase;
}

.contact-method__add {
  @apply text-grey-dark cursor-pointer;
}

.contact-method__edit {
  @apply w-5 h-5 cursor-pointer;
}

/* @include mq-max(bk(desk-down)) {
  .contact-method__item {
    > div {
      width: auto;
    }
  }
} */
