.monitoring-status {
  @apply flex items-center whitespace-nowrap;
}

.monitoring-status__icon-wrapper {
  @apply w-8 min-w-8 h-8 rounded-full
  flex justify-center items-center
  relative;
  box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.1);
}

.monitoring-status__icon {
  @apply w-5 h-5 rounded-full;

  &.archived {
    @apply opacity-50;
  }
  &.danger {
    @apply bg-red;
  }
  &.success {
    @apply bg-green;
  }
  &.warning {
    @apply bg-yellow;
  }
}

.monitoring-status__tooltip {
  @apply hidden group-hover:block
  min-w-24 px-4 py-2 rounded bg-white shadow-sm
  absolute bottom-[120%] left-1/2 -translate-x-1/2
  text-sm text-center whitespace-nowrap;
}