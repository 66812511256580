.plan {
  @apply p-4 border rounded border-gray-300 cursor-pointer;

  &--selected {
    @apply border-blue;
  }
}

.plan__check {
  @apply
    w-5 h-5 min-w-5 p-1
    rounded-full border-2 border-gray-300
  ;

  &--checked {
    @apply border-blue;
  }

  &--checked::after {
    content: '';
    @apply w-full h-full rounded-full bg-blue;
  }
}

.plan__name {
  @apply mb-1 text-lg font-bold leading-none;
}

.plan__price {
  @apply mb-1 font-bold text-lg leading-none;

  &--striked {
    @apply mb-0 text-sm font-medium text-gray-400 line-through;
  }
}