.box {
  @apply bg-white rounded-[20px];
  /* @apply bg-blue-white;
  border-radius: 20px;
  box-shadow: 15px 15px 20px #BCD2F0, -15px -15px 20px #fff;

  &--white {
    @apply bg-white;
    box-shadow: 10px 10px 30px rgba(#BCD2F0, .5), -10px -10px 30px rgba(#616365, .1);
  }
  &--flat {
    box-shadow: none;
  } */
}

@include mq-max(bk(desk-down)) {
  .box {
    box-shadow: none;
  }
}