.confirm-modal {
  @apply w-full h-full;
  background-color: rgba(#ECF0F3, .6);
  background-color: rgba(#616365, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 110;
}

.confirm-modal__content {
  width: 600px;
  background-color: #ECF0F3;
}
