.info-sidebar {
  width: $sidebar_width;
  height: 100%;
  padding: ($info_nav_height + $info_content_padding_v) space(l) $info_content_padding_v;
  @apply bg-white;
  border-right: 1px solid #C2C5C7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.info-sidebar__item {
  cursor: pointer;
}

@include mq-max(bk(desk-m-down)) {
  .info-sidebar {
    width: $sidebar_width_md;
  }
}