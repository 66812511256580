.input__label {
  @apply block text-sm font-medium mb-1;
}

.input__wrapper {
  @apply h-10 rounded shadow-sm;
}

.input {
  @apply w-full h-full px-3 order-2 text-sm text-grey-dark bg-white rounded border border-gray-300 hover:border-gray-400 focus:border-blue placeholder-gray-400 outline-none;

  &--prefix {
    @apply rounded-l-none border-l-0 pl-0;
  }
  &--suffix {
    @apply rounded-r-none border-r-0 pr-0;
  }
  &--error {
    @apply border-red;

    ~ .input__suffix,
    ~ .input__prefix {
      @apply border-red;
    }
  }

  &:hover {
    ~ .input__suffix,
    ~ .input__prefix {
      @apply border-gray-400;
    }
  }
  &:focus {
    ~ .input__suffix,
    ~ .input__prefix {
      @apply border-blue;
    }
  }
}

.input__suffix,
.input__prefix {
  @apply h-full px-3 border bg-white border-gray-300 text-gray-400 font-medium text-sm flex items-center;
}

.input__prefix {
  @apply order-1 border-r-0 rounded-l;

  .input__icon {
    @apply -translate-x-1;
  }
}

.input__suffix {
  @apply order-3 border-l-0 rounded-r;

  .input__icon {
    @apply translate-x-1;
  }
}

.input__error {
  @apply mt-1 text-red text-xs;
}

.input__icon {
  @apply w-8 h-8
  flex justify-center items-center;
}