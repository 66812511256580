.pagination {
  @apply flex justify-center text-xs text-grey-dark;
}

.pagination__item {
  @apply w-10 h-10 rounded bg-grey-light flex justify-center items-center cursor-pointer;

  &:hover {
    @apply bg-blue border-blue text-white;
  }

  &.active {
    @apply bg-blue border-blue font-semibold text-white pointer-events-none cursor-default
  }

  &.disabled {
    @apply bg-transparent pointer-events-none cursor-default;
  }

  &.round {
    @apply rounded-full;
  }
}

@include mq-max(bk(desk-down)) {
  .pagination {
    @apply flex-wrap
  }
}