.message {
  @apply flex flex-col items-center text-center;
}

.message__icon {
  @apply w-16 h-16 rounded-full
  flex justify-center items-center;

  &--danger {
    @apply bg-red bg-opacity-20 text-red-500;
  }
  &--generic {
    @apply bg-gray-100 text-gray-500;
  }
  &--info {
    @apply bg-blue bg-opacity-20 text-blue;
  }
  &--success {
    @apply bg-green-100 text-green-500;
  }
  &--warning {
    @apply bg-yellow bg-opacity-20 text-yellow-500;
  }
}

.message__title {
  @apply text-xl font-semibold mt-4;
}

.message__content {
  @apply mt-4;
}