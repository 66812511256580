.table {
  @apply w-full border-spacing-0;
  --grey: #E7EAEE;

  th, td {
    @apply px-2 lg:px-4 text-left;
  }
  th {
    @apply font-semibold;
  }
  thead {
    > tr {
      @apply h-10;
    }
  }
  tbody {
    > tr {
      @apply h-[60px] rounded-2xl cursor-pointer;

      &:first-child {
        > td {
          @apply border-t;
        }
      }

      &:hover {
        @apply relative;

        > td {
          @apply bg-slate-50;
        }
      }

      &.table__tr--disabled {
        @apply !cursor-default;
      }
    }
  }
}

.table__wrapper {
  @apply overflow-x-auto;
}

.table__th {
  @apply whitespace-nowrap cursor-default select-none;

  &--clickable {
    @apply cursor-pointer;
  }
  &--strict {
    @apply w-px;
  }
}

.table__th-content {
  @apply flex items-center;
}

.table__sort-icon {
  @apply flex items-start;

  svg {
    @apply w-5;
  }

  &:last-child {
    @apply -mt-2.5;
  }
}

.table__td {
  @apply min-w-[100px] max-w-[200px] md:min-w-auto lg:max-w-[12.5vw] text-sm border-b;

  > .text {
    @apply truncate;
  }

  &--strict {
    @apply min-w-px;
  }
}

.table__copy {
  @apply max-w-52;

  .text {
    @apply truncate;
  }
}

.table__actions {
  display: inline-flex;

  svg {
    @apply w-5;
  }
}
