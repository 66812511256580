.nav-mobile {
  display: none;
}

@include mq-max(bk(desk-down)) {
  .nav-mobile {
    display: block;
    width: 100%;
    height: 80px;
    background-color: #ECF0F3;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    @apply text-white;
    box-shadow: 0 -3px 8px rgba(#616365, .2);

    ul {
      height: 100%;
    }
  }
  .nav-mobile__menu {
    width: 80px;
    height: 80px;

    svg {
      width: 50px;
    }

    .menu-open & {
      .hex {
        fill: #73ACF9NavDark;
      }
    }
  }
  .nav-mobile__item {
    width: 80px;
    text-align: center;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 28px;
    }
  }
}