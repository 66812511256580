.protocol__title {
  @apply text-2xl font-semibold mb-4;
}

.protocol__table {
  @apply w-full;

  td {
    @apply py-4 align-top;
  }

  tr:not(:last-child) td {
    @apply border-b border-gray-300;
  }
}

.protocol__td-title {
  @apply font-semibold whitespace-nowrap pr-4;
}

.protocol__td-text {
  @apply pl-4;
}

.protocol__list {
  @apply pl-8;
}