.chip {
  @apply h-8 px-4 flex justify-between items-center rounded-2xl bg-blue text-white;
}

.chip__text {
  @apply max-w-52 truncate;
}

.chip__icon {
  @apply flex items-start cursor-pointer ml-1;

  svg {
    @apply w-[18px];
  }
}
