.header {
  @apply sticky top-0 left-0 bg-white border-b border-grey-border z-30;

  &--fixed {
    @apply w-full fixed;
  }
}

.header__wrapper {
  @apply px-4 sm:px-6 lg:px-8;
}

.header__content {
  @apply flex items-center justify-between h-16 -mb-px;
}

.header__left {
  @apply flex;
}

.header__menu {
  @apply hidden
  lg:flex items-center gap-x-8 2xl:gap-x-10;
}

.header__menu-item {
  @apply text-sm hover:text-blue-dark;
}

.header__right {
  @apply flex items-center space-x-3;
}

.header__toggle {
  @apply text-grey-text lg:hidden;
}

.header__user {
  @apply relative inline-flex;
}

.header__user-toggle {
  @apply inline-flex justify-center items-center;
}

.header__user-avatar {
  @apply w-10 h-10 rounded-full border border-grey-border
  flex justify-center items-center
  uppercase text-blue-dark;
}

.header__user-select {
  @apply flex items-center truncate;
}

.header__user-name {
  @apply truncate ml-2 text-sm font-medium;
}

.header__user-icon {
  @apply w-3 h-3 flex-shrink-0 ml-1 fill-current text-grey;
}

.header__dropdown {
  @apply min-w-56 origin-top-right z-10 absolute top-full bg-white border border-grey-light py-1.5 rounded shadow-lg overflow-hidden mt-1 right-0;
}

.header__dropdown-header {
  @apply pt-0.5 pb-2 px-3 mb-1 border-b border-grey-light;
}

.header__dropdown-name {
  @apply whitespace-nowrap;
}

.header__dropdown-role {
  @apply text-xs text-grey italic;
}

.header__dropdown-list {
  @apply text-sm;
}

.header__dropdown-item {
  @apply text-gray-500 hover:text-gray-600 flex items-center py-1 px-3 cursor-pointer;

  &--blue {
    @apply text-blue hover:text-blue-dark;
  }
}
