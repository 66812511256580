.dot-menu {
  @apply w-8 h-8 relative select-none whitespace-nowrap;
}

.dot-menu__icon {
  @apply w-full h-full flex justify-center items-center cursor-pointer;

  svg {
    @apply w-5;
  }
}

.dot-menu__list {
  @apply
    bg-white rounded shadow-lg
    absolute top-1/2 right-full z-1 -translate-y-1/2
    text-sm font-normal
  ;

  li {
    @apply px-4 py-2 last:border-b-0 cursor-pointer
    hover:bg-blue-dark hover:text-white;
  }
}

.dot-menu__list-wrapper {
  @apply fixed z-1;

  .dot-menu__list {
    @apply static translate-y-0;
  }
}
