.modal__backdrop {
  @apply fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity;
}

.modal__dialog {
  @apply fixed inset-0 z-50 overflow-hidden flex items-center my-4 sm:my-6 justify-center transform px-4 sm:px-6;
}

.modal {
  @apply
    max-w-lg w-full max-h-full
    bg-white rounded shadow-lg
    flex flex-col
  ;
}

.modal__header {
  @apply
    min-h-12 px-5 py-3 border-b border-gray-200
    flex justify-between items-center
  ;
}

.modal__header-title {
  @apply font-semibold text-gray-800;
}

.modal__header-button {
  @apply
    w-10 min-w-10 h-10 ml-2 -mr-2.5
    flex justify-center items-center
    text-gray-400 hover:text-gray-500
  ;
}

.modal__content {
  @apply px-5 py-4 flex flex-col overflow-auto;
}

.modal__content-title {
  @apply font-medium text-gray-800 mb-2;
}

.modal__footer {
  @apply px-5 py-4 border-t border-gray-200;
}
