@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Config */
@import "01_config/config";

/* Base */
@import "02_base/base";
@import "02_base/components";
@import "02_base/typography";
@import "02_base/layout";

/* Atoms */
@import "03_atoms/accordion";
@import "03_atoms/alert";
@import "03_atoms/box";
@import "03_atoms/banner";
@import "03_atoms/button";
@import "03_atoms/card";
@import "03_atoms/checkbox";
@import "03_atoms/chip";
@import "03_atoms/icon";
@import "03_atoms/info";
@import "03_atoms/input";
@import "03_atoms/label";
@import "03_atoms/message";
@import "03_atoms/modal";
@import "03_atoms/radio";
@import "03_atoms/row";
@import "03_atoms/select";
@import "03_atoms/spinner";
@import "03_atoms/switch";
@import "03_atoms/textarea";
@import "03_atoms/time-picker";

/* Components */
@import "04_components/account-complete";
@import "04_components/calendar";
@import "04_components/confirm-modal";
@import "04_components/contact-method";
@import "04_components/copy-clipboard";
@import "04_components/date-picker";
@import "04_components/dot-menu";
@import "04_components/dropzone";
@import "04_components/feedback-section";
@import "04_components/filters";
@import "04_components/float-widget";
@import "04_components/footer";
@import "04_components/form";
@import "04_components/header";
@import "04_components/heading";
@import "04_components/invoices";
@import "04_components/info-nav";
@import "04_components/info-sidebar";
@import "04_components/listing";
@import "04_components/loader";
@import "04_components/logout-widget";
@import "04_components/menu-mobile";
@import "04_components/mobile-banner";
@import "04_components/modals";
@import "04_components/monitoring-status";
@import "04_components/nav";
@import "04_components/nav-mobile";
@import "04_components/pagination";
@import "04_components/patient-select";
@import "04_components/patient-message";
@import "04_components/payment-method";
@import "04_components/percentage-progress";
@import "04_components/percentage-widget";
@import "04_components/plan";
@import "04_components/report-answer";
@import "04_components/report-modal";
@import "04_components/report-send";
@import "04_components/sidebar";
@import "04_components/table";

/* Pages */
@import "05_pages/account";
@import "05_pages/check";
@import "05_pages/csv";
@import "05_pages/login";
@import "05_pages/monitoring";
@import "05_pages/patient";
@import "05_pages/patients";
@import "05_pages/protocols";

/* Shame */
@import "shame";
