.card {
  /* @apply bg-slate-50 shadow-lg rounded border border-gray-200; */
  @apply bg-white rounded border border-gray-200;
}

.card-heading {
  @apply w-full px-5 py-4 border-b border-gray-100 font-bold;
}

.card-content {
  @apply w-full p-5;
}
