.calendar {
  @apply
    px-4 py-2 bg-white rounded
    absolute top-full left-1/2 -translate-x-1/2 z-1
    shadow-md select-none
  ;
}

.calendar__header {
  @apply flex justify-between items-center;
}
.calendar__header-titles {
  @apply flex gap-x-1;
}
.calendar__header-title {
  @apply hover:underline cursor-pointer;
}

.calendar__arrow {
  @apply
    w-9 h-9 rounded-full
    flex justify-center items-center
    text-gray-400 hover:bg-gray-100
    cursor-pointer
  ;
}

.calendar__content {
  @apply mt-2 relative;
}

.calendar__weekdays {
  @apply grid grid-cols-7 gap-x-1;
}
.calendar__weekday {
  @apply col-span-1 text-xs text-gray-400 text-center;
}

.calendar__days {
  @apply py-2 grid grid-cols-[repeat(7,_minmax(36px,_1fr))] gap-1 text-xs;
}
.calendar__day {
  @apply
    w-9 h-9
    rounded-full hover:bg-blue hover:bg-opacity-20
    flex justify-center items-center
    cursor-pointer
  ;

  &--selected {
    @apply bg-blue hover:bg-blue-dark text-white;
  }
}

.calendar__picker {
  @apply w-full h-full bg-white
  flex flex-col
  absolute top-0 left-0;
}
.calendar__picker-list {
  @apply flex-1 overflow-auto no-scrollbar relative;
}
.calendar__picker-item {
  @apply h-10
  flex justify-center items-center text-center hover:bg-blue hover:bg-opacity-20 cursor-pointer;

  &--selected {
    @apply bg-blue hover:bg-blue-dark text-white;
  }
}