.logout-widget {
  position: fixed;
  top: space(l);
  right: space(l);
  z-index: 10;
}

@include mq-max(bk(desk-m-down)) {
  .logout-widget {
    top: space_md(l);
    right: space_md(l);
  }
}