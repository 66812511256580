.info-nav {
  width: 100%;
  height: $info_nav_height;
  padding-left: $sidebar_width;
  @apply bg-white;
  border-bottom: 1px solid #C2C5C7;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.info-nav__logo {
  width: 48px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 160px;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
  }
}

.info-nav__list {
  padding: 0 $info_content_padding_h;
}

.info-nav__item {
  position: relative;
  padding-bottom: 4px;

  &::before {
    content: '';
    width: 100%;
    height: 4px;
    background-color: #73ACF9;
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: left center;
    transform: scaleX(0);
  }

  &--active {
    color: #73ACF9;

    &::before {
      transform: scaleX(1);
    }
  }
}

@include mq-max(bk(desk-m-down)) {
  .info-nav {
    padding-left: $sidebar_width_md;
  }
}