.row {
  display: flex;

  &--align-start {
    align-items: flex-start;
  }
  &--align-center {
    align-items: center;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--align-stretch {
    align-items: stretch;
  }

  &--justify-start {
    justify-content: flex-start;
  }
  &--justify-center {
    justify-content: center;
  }
  &--justify-end {
    justify-content: flex-end;
  }
  &--justify-between {
    justify-content: space-between;
  }
  &--justify-around {
    justify-content: space-around;
  }

  &--wrap {
    flex-wrap: wrap;
  }
}

.col--full {
  width: 100%;
  flex: 1;
}

@include mq-max(bk(desk-down)) {
  .row {
    &--m-col {
      flex-direction: column;
    }
    &--m-wrap {
      flex-wrap: wrap;
    }

    &--m-align-start {
      align-items: flex-start;
    }
    &--m-align-center {
      align-items: center;
    }
    &--m-align-end {
      align-items: flex-end;
    }

    &--m-justify-start {
      justify-content: flex-start;
    }
    &--m-justify-center {
      justify-content: center;
    }
    &--m-justify-end {
      justify-content: flex-end;
    }
    &--m-justify-between {
      justify-content: space-between;
    }
    &--m-justify-around {
      justify-content: space-around;
    }
  }
}
