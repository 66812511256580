.switch__container {
  user-select: none;
}

.switch {
  width: 40px;
  min-width: 40px;
  height: 20px;
  padding: 1px;
  border-radius: 10px;
  background-color: #DDE2E5;
  transition: background-color .3s;

  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    @apply bg-white;
    transition: transform .3s;
  }

  &--color {
    background-color: #F27D73;
  }

  &--checked {
    background-color: #73ACF9;

    &.switch--neutral {
      background-color: #4BAF62;
    }

    &::before {
      transform: translateX(20px);
    }
  }
}