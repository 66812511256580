.icon {
  &--grey-dark {
    path {
      fill: #616365;
    }
  }
  &--grey-light {
    path {
      fill: #DDE2E5;
    }
  }
  &--grey-text {
    path {
      fill: #868A8E;
    }
  }
  &--blue {
    path {
      fill: #73ACF9;
    }
  }
  &--blue-mobile {
    path {
      fill: #73ACF9NavMobile;
    }
  }
  &--red {
    path {
      fill: #F27D73;
    }
  }
  &--green {
    path {
      fill: #4BAF62;
    }
  }
  &--yellow {
    path {
      fill: #FDC55A;
    }
  }
  &--black {
    path {
      fill: $black;
    }
  }
  &--white {
    path {
      fill: #FFFFFF;
    }
  }
}
