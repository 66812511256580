.button {
	@apply h-10 px-8 border outline-none rounded inline-flex justify-center items-center text-sm font-semibold leading-none uppercase text-white cursor-pointer
	disabled:opacity-50 disabled:pointer-events-none disabled:cursor-default select-none;

	svg {
		@apply w-4;
	}

	&--blue {
		@apply bg-blue;
	}
	&--blue-outline {
		@apply bg-transparent border-blue text-blue;
	}
	&--green {
		@apply bg-green;
	}
	&--green-outline {
		@apply bg-transparent border-green text-green;
	}
	&--red {
		@apply bg-red;
	}
	&--red-outline {
		@apply bg-transparent border-red text-red;
	}

	&--full {
		@apply w-full;
	}
	&--fixed {
		@apply w-48;
	}
	&--fixed-l {
		@apply w-72;
	}
	&--check {
		@apply w-[300px] h-[60px] bg-white p-0
	  border border-blue text-blue;
		text-transform: none;
		box-shadow: 0 0 10px rgba(#73ACF9, .2);

		&.active {
			background-color: #73ACF9;
			@apply text-white bg-blue;
		}
		&.animate {
			transition: none;
			animation: check-blue .35s ease-in-out forwards;
		}
	}
	/* &--check-good {
		border-color: #4BAF62;
		color: #4BAF62;

		&.active {
			background-color: #4BAF62;
			@apply text-white;
		}
		&.animate {
			transition: none;
			animation: check-green .35s ease-in-out forwards;
		}
	}
	&--check-bad {
		border-color: #F27D73;
		color: #F27D73;

		&.active {
			background-color: #F27D73;
			@apply text-white;
		}
		&.animate {
			transition: none;
			animation: check-red .35s ease-in-out forwards;
		}
	} */

	&--nowrap {
		white-space: nowrap;
	}

	&--icon {
		width: 40px;
		min-width: 40px;
		padding: 0;
	}
	/* &:disabled {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	} */
}

/* @keyframes check-green {
	0% {
		box-shadow: 0 0 0 0 rgba(#4BAF62, 0);
	}
	50% {
		box-shadow: 0 0 16px 12px rgba(#4BAF62, .25);
		background-color: #4BAF62;
		@apply text-white;
	}
	100% {
		box-shadow: 0 0 0 0 rgba(#4BAF62, 0);
		background-color: #4BAF62;
		@apply text-white;
	}
}

@keyframes check-red {
	0% {
		box-shadow: 0 0 0 0 rgba(#F27D73, 0);
	}
	50% {
		box-shadow: 0 0 16px 12px rgba(#F27D73, .25);
		background-color: #F27D73;
		@apply text-white;
	}
	100% {
		box-shadow: 0 0 0 0 rgba(#F27D73, 0);
		background-color: #F27D73;
		@apply text-white;
	}
} */

@keyframes check-blue {
	0% {
		box-shadow: 0 0 0 0 rgba(#73ACF9, 0);
	}
	50% {
		box-shadow: 0 0 16px 12px rgba(#73ACF9, .25);
		background-color: #73ACF9;
		@apply text-white;
	}
	100% {
		box-shadow: 0 0 0 0 rgba(#73ACF9, 0);
		background-color: #73ACF9;
		@apply text-white;
	}
}
