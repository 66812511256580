.check {
  @apply bg-white;
}

.check__intro-logo {
  width: 64px;
}

.check__questions {
  @apply h-full flex flex-col;
}

.check__questions-logo {
  @apply py-4;
}

.check__questions-progress {
  @apply w-full h-2 min-h-2;

  &::before {
    content: '';
    @apply block w-full h-full bg-blue;
    transform-origin: left center;
    transform: scaleX(var(--progress));
    transition: transform .4s;
  }
}

.check__questions-content {
  @apply px-6 bg-white
  flex-1 flex flex-col justify-between;
}
.check__answers {
  @apply flex-1 flex flex-col justify-end overflow-y-auto md:overflow-y-visible pt-8 pb-24 lg:pb-48;
}

.check__questions-title {
  @apply lg:max-w-[60%];
}

.check__bool {
  @apply flex flex-wrap justify-center gap-4;
}

.check__radio {
  @apply relative;
}

.check__radio-descriptor {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.check__end-icon {
  svg {
    width: 60px;
  }
}

.check__textarea {
  width: 500px;
  height: 250px;
  padding: 8px;
  resize: none;
  border: 1px solid #DDE2E5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(#73ACF9, .2);

  &:focus {
    outline: 1px solid #73ACF9;
  }
}

.check__buttons {
  @apply w-full bg-white
  flex justify-center lg:gap-4
  fixed bottom-0 left-0;

  .button {
    @apply w-1/2 lg:w-[300px] h-[60px] rounded-none;
  }
}

@include mq-max(bk(desk-down)) {
  .check {
    height: auto;
    min-height: 100%;
  }
  .check__intro-logo {
    margin-bottom: 32px;
  }
  .check__intro-button {
    width: 100%;
  }
  .check__box {
    box-shadow: none;
    padding-right: 5%;
    padding-left: 5%;
  }
  .check__questions-logo {
    width: 64px;
    padding-top: 32px;
    padding-bottom: 0;
  }
  .check__questions-content {
    padding-bottom: 94px;
  }
  .check__questions-title {
    margin-bottom: 48px;
    max-width: 90%;
    font-size: 24px;
  }
  .check__bool {
    flex-direction: column;

    .button {
      &:last-child {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }
  .check__radio {
    display: flex;
    align-items: center;
    margin-bottom: space(m);
  }
  .check__radio-label {
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .check__radio-descriptor {
    display: none;
  }
  .check__textarea {
    width: 90%;
    height: 160px;
  }
}
