.menu-mobile {
  display: none;
}

@include mq-max(bk(desk-down)) {
  .menu-mobile {
    display: block;
    @apply w-full h-full;
    padding-bottom: 80px;
    background-color: #ECF0F3;
    @apply text-white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .menu-mobile__content {
    height: 100%;
    padding-right: 5%;
    padding-left: 5%;
    overflow-y: scroll;
  }
  .menu-mobile__item {
    width: 46%;
    height: 38vw;
    background-color: #73ACF9NavDark;
    border-radius: 15px;
    position: relative;

    a, span {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      @apply w-full h-full;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
