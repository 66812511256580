.reports-modal {
  @apply h-[80vh];
}
.reports-modal__content {
  @apply flex-1 flex flex-col;
}
.reports-modal__message {
  @apply flex-1 flex flex-col justify-center;
}

.report-detail__info {
  @apply py-1 border-b border-gray-300 flex justify-between items-center;
}
.report-detail__info-title {
  @apply text-sm font-semibold;
}
.report-detail__info-value {
  @apply text-sm;
}