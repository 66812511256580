.select {
  &--error {
    [class*="Control"] {
      border-color: #F27D73;
    }
    [class*="placeholder"] {
      color: #F27D73;
    }
  }
}

.select .select__container {
  @apply block;
}

.select .select__control {
  @apply
    h-10 flex bg-white
    rounded border border-gray-300 hover:border-gray-400
    text-sm font-medium text-grey-text
    shadow-none
  ;

  &.select__control--is-focused,
  &.select__control--menu-is-open {
    @apply border-blue;
  }
}
.select--error .select__control {
  @apply border-red hover:border-red;
}

.select .select__value-container {
  @apply
    flex-1 h-full pl-3
    flex justify-start items-center
    overflow-hidden
  ;
}

.select .select__single-value {
  @apply text-grey-dark;
}

.select .select__placeholder {
  @apply text-gray-400 cursor-default;
}

.select .select__indicators {
  @apply h-full;
}

.select .select__indicator {
  @apply p-0;
}

.select .select__clear-indicator {
  @apply
    text-red text-opacity-75 hover:text-red hover:text-opacity-100 cursor-pointer
  ;
}

.select .select__dropdown-indicator {
  @apply
    w-10 h-full
    flex justify-center items-center
    text-grey-text hover:text-grey-dark
    rotate-0 transition-transform duration-300
  ;
}
.select__control--menu-is-open .select__dropdown-indicator {
  @apply -rotate-180;
}

.select .select__menu {
  @apply w-full bg-white rounded left-0 overflow-hidden;
}

.select .select__menu-list {
  @apply p-0;
}

.select .select__option {
  @apply text-sm hover:bg-blue hover:text-white;

  &.select__option--is-selected {
    @apply bg-transparent font-bold text-grey-dark hover:bg-blue hover:text-white;
  }
}

.select .select__menu-notice {
  @apply cursor-default text-sm text-gray-400;
}