.invoices {
  width: 100%;

  th {
    padding: 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: #868A8E;
  }

  tbody td {
    border-bottom: 1px solid #BEC2C6;
  }

  th, td {
    width: auto;
    text-align: left;

    &:last-of-type {
      text-align: right;
    }
  }

  td {
    padding: 8px 0;
  }
}

.invoices__status {
  display: inline-block;
  padding: 2px 8px;
  border: 2px solid #F27D73;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  color: #F27D73;

  &--paid {
    border-color: #4BAF62;
    color: #4BAF62;
  }
}
